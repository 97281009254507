import * as React from "react";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/tunisia-jobs-5-18.png";
import Leverage from "../assets/img/leverage.jpg";
import Diaspora from "../assets/img/diaspora.jpg";
import multinationals from "../assets/img/multinationals.jpg";
import Fintech from "../assets/img/fintech.jpg";
import recycle from "../assets/img/recycle.jpg";
import Healthcare from "../assets/img/healthcare.jpg";
const TechnologyAndInnovation = () => (
  <Layout>
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-technologyinnovation"
            className="block block-block-content block-block-content6fa61d12-3601-42c5-8fa2-00940b5fa97a"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img src={BannerEffect} alt="" />
              </div>
              <img src={TunisiaJobs} alt="" />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    Technology and Innovation
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Helping <span>innovative</span> Tunisian start-ups
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={53}
              role="article"
              about="/technology-and-innovation"
              className="node node--type-activities node--promoted node--view-mode-full"
            >
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <div className="inner-card">
                                <h3>The Sector</h3>
                                <p>
                                  Technology and Innovation (T&amp;I) is one of
                                  Tunisia’s fastest-growing economic sectors,
                                  averaging over 8% annual growth over the last
                                  five years. Led by well-trained youth,
                                  technology-driven growth is apt to be one of
                                  the most important factors in increasing
                                  incomes in Tunisia over the long term. The
                                  constant brain drain to other countries,
                                  however, threatens this potential.
                                </p>
                              </div>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay
                            >
                              <div className="inner-card">
                                <h3>JOBS’ Vision and Strategy</h3>
                                <p>
                                  Tunisia JOBS’ vision is to support Tunisia
                                  start-ups, early-stage technology companies,
                                  as well as companies that can take
                                  transformative leaps in their current business
                                  models to become regional and international
                                  players.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="our-work">
                      <div className="brick brick--type--our-work-workforce brick--id--79 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Our Work
                          </div>
                        </h2>
                        <div className="aos-animate" data-aos="fade-up">
                          <p>
                            Tunisia JOBS supports entrepreneurs and companies in
                            a wide range of sectors, such as financial
                            technology, healthcare (biotech and health-tech),
                            aerospace and automotive, and light manufacturing -
                            Industry 4.0 and Internet of Things (IoT).
                          </p>
                          <p>
                            <span>The project is focusing on:</span>
                          </p>
                        </div>
                        <div className="work-card-wrapper">
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={Leverage} alt="" />
                            <div className="about-work">
                              <h5>
                                Leveraging Tunisia’s talented youth in the
                                technology field
                              </h5>
                              <p>
                                JOBS enables entrepreneurs and investors to tap
                                Tunisia’s technology talent by making skilled
                                youth easier for employers to identify and
                                ensuring that youth are better prepared to join
                                the workforce. For example, JOBS is helping to
                                develop an online database of skilled youth and
                                companies in Tunisia, including developers,
                                start-ups, and research institutions, that will
                                provide technology companies with critical
                                insight when considering Tunisia as an
                                investment location. JOBS also teamed up with
                                Vermeg, a financial markets solutions provider,
                                to create the first fintech master’s program in
                                the region. The project is also working with the
                                Ministry of Higher Education and the ICT
                                Federation to reform university curricula that
                                will better prepare youth for today’s technology
                                jobs.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={Diaspora} alt="" />
                            <div className="about-work">
                              <h5>Tapping the Tunisian diaspora</h5>
                              <p>
                                JOBS is partnering with Tunisians abroad that
                                wish to invest in the Tunisian market and return
                                home, reversing the “brain drain.” JOBS has
                                partnered with Betacube, an ICT start-up that
                                provides support services for diaspora
                                entrepreneurs to launch innovative companies
                                based in Tunisia. The project is also supporting
                                technology companies in the Zarzis Technology
                                Park, comprised primarily of entrepreneurs who
                                left Tunisia but now see technology
                                opportunities in their native home.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={0}
                          >
                            <img src={multinationals} alt="" />
                            <div className="about-work">
                              <h5>Creating micro-multinationals</h5>
                              <p>
                                JOBS partners with emerging companies to help
                                them become more competitive in regional and
                                international markets. One example is supporting
                                Aymax Technology, a digital services company
                                (DSC), to implement an integrated quality and
                                security management system. JOBS also partnered
                                with Swiver, Tunisia’s main web-based invoicing
                                solution, to implement a data security
                                management system compliant with international
                                standards and to implement new organizational
                                processes to facilitate their growth.
                                Additionally, JOBS is supporting Integration
                                Objects, a solution provider that allows
                                companies to monitor their production facilities
                                remotely, to expand its market internationally.
                                JOBS partnered with DigitalMania, a video game
                                developer and programming studio, to become a
                                key player in the video games industry in the
                                Middle East and Africa (MEA).
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <img src={Fintech} alt="" />
                            <div className="about-work">
                              <h5>Developing the fintech sector</h5>
                              <p>
                                Financial technology is key to providing SMEs
                                with greater access to finance. JOBS is
                                supporting numerous start-ups and major
                                financial institutions. JOBS partnered with
                                Kaoun, a mobile wallet startup that offers
                                mobile payments and credit services contributing
                                to financial inclusion for many Tunisians not
                                integrated into the financial system. JOBS
                                client Paypos offers secure, turnkey payment
                                solutions using integrated payment terminals.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay
                          >
                            <img src={recycle} alt="" />
                            <div className="about-work">
                              <h5>
                                Increasing value added in strategic sectors such
                                as automotive and aerospace
                              </h5>
                              <p>
                                JOBS is helping Tunisian companies in these
                                sectors to strengthen their respective supply
                                chains through innovation. JOBS partnered with
                                ECHO to enable the aviation industry to rapidly
                                retrofit planes. JOBS is also supporting Wallys
                                Car, the only local car manufacturer in the
                                region, to expand its production from 50 cars
                                currently to 2,500 cars per year by 2025. JOBS
                                has also partnered with OneTech, a local
                                champion in the mechatronic and cabling
                                industry, to move from a Tier 2 to Tier 1 auto
                                parts supplier, thereby adding value to its
                                existing activities.
                              </p>
                            </div>
                          </div>
                          <div
                            className="work-card card aos-animate"
                            data-aos="zoom-in-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay
                          >
                            <img src={Healthcare} alt="" />
                            <div className="about-work">
                              <h5>
                                Promoting an innovative green eEconomy: reuse,
                                reduce, recycle
                              </h5>
                              <p>
                                JOBS is supporting companies that recycle/reuse
                                materials that are traditionally considered
                                waste. JOBS is also supporting Sunlife Afrique,
                                a start-up developing renewable energy solutions
                                for businesses in the underserved region of
                                Kairouan. JOBS has partnered with several
                                companies in the waste management sector (Dasri
                                Sterile, Solutions Medicales et Services, Galien
                                Environnement, Techno-Soin, and Tunisian
                                Environmental Services) to improve the treatment
                                and recycling of toxic and high-risk waste. JOBS
                                also supported EMECC, an SME specializing in
                                remanufacturing and maintenance of industrial
                                cooling racks, chillers, blast freezers, ice
                                machines, AC &amp; refrigeration units.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--47 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2>
                          <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                            Tunisia JOBS has assisted 60 firms through 50
                            partnership agreement in the technology and
                            innovation field. This support is projected to:
                          </div>
                        </h2>
                        <ul className="pl-0">
                          <li>
                            <span>Create more than 2,000 jobs.</span>
                          </li>
                          <li>
                            <span>
                              Help companies increase sales by over 200 million
                              TND.
                            </span>
                          </li>
                          <li>
                            <span>
                              Raise more than 160 million TND in equity.
                            </span>
                          </li>
                          <li>
                            <span>
                              Retain Tunisian technology talent in Tunisia.
                            </span>
                          </li>
                          <li>
                            <span>
                              Help brand Tunisia as a regional technology hub.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);
export default TechnologyAndInnovation;
